import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProtectedRoute from "utils/protectedRoute";
import Links from "pages/Home";
import NotFound from "pages/NotFound";
import HomeMain from "pages/universalPage/homeMain";
const Error1 = React.lazy(() => import("pages/Error1"));
const VerticalLinearStepper = React.lazy(() => import("pages/VerticalLinearStepper"));
const Error = React.lazy(() => import("pages/Error"));
const FAQs = React.lazy(() => import("pages/FAQs"));
const WhatsNew = React.lazy(() => import("pages/WhatsNew"));
const Databaselocked = React.lazy(() => import("pages/Databaselocked"));
const StepsSix = React.lazy(() => import("pages/StepsSix"));
const YourModelsTwo = React.lazy(() => import("pages/YourModelsTwo"));
const Code = React.lazy(() => import("pages/Code"));
const StepsTwelve = React.lazy(() => import("pages/StepsTwelve"));
const StepsEleven = React.lazy(() => import("pages/StepsEleven"));
const StepsTen = React.lazy(() => import("pages/StepsTen"));
const StepsNine = React.lazy(() => import("pages/StepsNine"));
const StepsEight = React.lazy(() => import("pages/StepsEight"));
const StepsSeven = React.lazy(() => import("pages/StepsSeven"));
const StepsFive = React.lazy(() => import("pages/StepsFive"));
const StepsFour = React.lazy(() => import("pages/StepsFour"));
const StepsThree = React.lazy(() => import("pages/StepsThree"));
const StepsTwo = React.lazy(() => import("pages/StepsTwo"));
const StepsOne = React.lazy(() => import("pages/StepsOne"));
const Review = React.lazy(() => import("pages/Review"));
const BasicDetailsFour = React.lazy(() => import("pages/BasicDetailsFour"));
const BasicDetailsSeven = React.lazy(() => import("pages/BasicDetailsSeven"));

const CapabilitiesselectedTwo = React.lazy(() =>
  import("pages/CapabilitiesselectedTwo")
);
const DatabaseThree = React.lazy(() => import("pages/DatabaseThree"));
const DatabaseTwo = React.lazy(() => import("pages/DatabaseTwo"));
const BackEndOne = React.lazy(() => import("pages/BackEndOne"));
const FETemplatehoverOne = React.lazy(() => import("pages/FETemplatehoverOne"));
const FETemplateaddedOne = React.lazy(() => import("pages/FETemplateaddedOne"));
const FETemplateopenOne = React.lazy(() => import("pages/FETemplateopenOne"));
const FETemplateOne = React.lazy(() => import("pages/FETemplateOne"));
const FETechStackselectedOne = React.lazy(() =>
  import("pages/FETechStackselectedOne")
);
const FETechStackOne = React.lazy(() => import("pages/FETechStackOne"));
const ResetPasswordEight = React.lazy(() => import("pages/ResetPasswordEight"));
const ResetPasswordTen = React.lazy(() => import("pages/ResetPasswordTen"));
const ResetPasswordNine = React.lazy(() => import("pages/ResetPasswordNine"));
const ResetPasswordFive = React.lazy(() => import("pages/ResetPasswordFive"));
const ResetPasswordFour = React.lazy(() => import("pages/ResetPasswordFour"));
const ResetPasswordThree = React.lazy(() => import("pages/ResetPasswordThree"));
const ResetPasswordTwo = React.lazy(() => import("pages/ResetPasswordTwo"));
const ResetPasswordOne = React.lazy(() => import("pages/ResetPasswordOne"));
const DatabaselockedTwo = React.lazy(() => import("pages/DatabaselockedTwo"));
const DatabaselockedThree = React.lazy(() =>
  import("pages/DatabaselockedThree")
);
const CodeTwo = React.lazy(() => import("pages/CodeTwo"));
const CodeGeneratedTwo = React.lazy(() => import("pages/CodeGeneratedTwo"));
const Frame53226 = React.lazy(() => import("pages/Frame53226"));
const StepsFortyNine = React.lazy(() => import("pages/StepsFortyNine"));
const StepsFortyEight = React.lazy(() => import("pages/StepsFortyEight"));
const StepsFortySeven = React.lazy(() => import("pages/StepsFortySeven"));
const StepsFortySix = React.lazy(() => import("pages/StepsFortySix"));
const StepsFortyFive = React.lazy(() => import("pages/StepsFortyFive"));
const StepsFortyFour = React.lazy(() => import("pages/StepsFortyFour"));
const StepsFortyThree = React.lazy(() => import("pages/StepsFortyThree"));
const CapabilitiesselectedOne = React.lazy(() =>
  import("pages/CapabilitiesselectedOne")
);
const BackEnd = React.lazy(() => import("pages/BackEnd"));
const ForgotPasswordFive = React.lazy(() => import("pages/ForgotPasswordFive"));
const ForgotPasswordFour = React.lazy(() => import("pages/ForgotPasswordFour"));
const ForgotPasswordThree = React.lazy(() =>
  import("pages/ForgotPasswordThree")
);
const ForgotPasswordTwo = React.lazy(() => import("pages/ForgotPasswordTwo"));
const ForgotPasswordOne = React.lazy(() => import("pages/ForgotPasswordOne"));
const CodeOne = React.lazy(() => import("pages/CodeOne"));
const StepsThirtyOne = React.lazy(() => import("pages/StepsThirtyOne"));
const StepsThirty = React.lazy(() => import("pages/StepsThirty"));
const StepsTwentyNine = React.lazy(() => import("pages/StepsTwentyNine"));
const StepsTwentyEight = React.lazy(() => import("pages/StepsTwentyEight"));
const StepsTwentySeven = React.lazy(() => import("pages/StepsTwentySeven"));
const StepsTwentySix = React.lazy(() => import("pages/StepsTwentySix"));
const StepsThirteen = React.lazy(() => import("pages/StepsThirteen"));
const Capabilitiesselected = React.lazy(() =>
  import("pages/Capabilitiesselected")
);
const FETemplatehover = React.lazy(() => import("pages/FETemplatehover"));
const FETemplateadded = React.lazy(() => import("pages/FETemplateadded"));
const FETemplateopen = React.lazy(() => import("pages/FETemplateopen"));
const FETemplate = React.lazy(() => import("pages/FETemplate"));

const FETechStack = React.lazy(() => import("pages/FETechStack"));
const ModelLibraryOne = React.lazy(() => import("pages/ModelLibraryOne"));
const ModelLibrarynotfound = React.lazy(() =>
  import("pages/ModelLibrarynotfound")
);
const YourModels = React.lazy(() => import("pages/YourModels"));
const ModelLibrarynotfoundOne = React.lazy(() =>
  import("pages/ModelLibrarynotfoundOne")
);
const ModelLibrarysearchresult = React.lazy(() =>
  import("pages/ModelLibrarysearchresult")
);
const ModelLibrarysearchactive = React.lazy(() =>
  import("pages/ModelLibrarysearchactive")
);
const OTPOne = React.lazy(() => import("pages/OTPOne"));
const LoginSix = React.lazy(() => import("pages/LoginSix"));
const LoginEight = React.lazy(() => import("pages/LoginEight"));
const LoginFour = React.lazy(() => import("pages/LoginFour"));
const AddCardDetailsNineteen = React.lazy(() =>
  import("pages/AddCardDetailsNineteen")
);
const BillingAddressTen = React.lazy(() => import("pages/BillingAddressTen"));
const Allfeaturesandbenefits = React.lazy(() =>
  import("pages/Allfeaturesandbenefits")
);
const AllfeaturesandbenefitsOne = React.lazy(() =>
  import("pages/AllfeaturesandbenefitsOne")
);
const EditCardDetails = React.lazy(() => import("pages/EditCardDetails"));
const MyAccountbillingTwo = React.lazy(() =>
  import("pages/MyAccountbillingTwo")
);
const MyAccountbillingOne = React.lazy(() =>
  import("pages/MyAccountbillingOne")
);
const PaymentDetailsEleven = React.lazy(() =>
  import("pages/PaymentDetailsEleven")
);
const SelectPlans = React.lazy(() => import("pages/SelectPlans"));
const SelectPlansOne = React.lazy(() => import("pages/SelectPlansOne"));
const MyAccountPassword = React.lazy(() => import("pages/MyAccountPassword"));
const OTPFour = React.lazy(() => import("pages/OTPFour"));
const SignupEleven = React.lazy(() => import("pages/SignupEleven"));
const MyAccountMyDetailsNine = React.lazy(() =>
  import("pages/MyAccountMyDetailsNine")
);

const Signup = React.lazy(() => import("screens/Signup"));
const Pricing = React.lazy(() => import("screens/Pricing"));
const FrontendApp = React.lazy(() => import("screens/frontendApp"));
const BackendApp = React.lazy(() => import("screens/backendApp"));
const ModelLibrary = React.lazy(() => import("screens/ModelLibrary"));
const FECodeGenDetail = React.lazy(() => import("screens/FECodeGenDetail"));
const BECodeGenDetail = React.lazy(() => import("screens/BECodeGenDetail"));
const Home = React.lazy(() => import("screens/Home"));
const Settings = React.lazy(() => import("screens/Settings"));
const PreBuiltApps = React.lazy(() => import("screens/PreBuiltApps"));
const HelpandSupport = React.lazy(() => import("screens/HelpAndSupport"));

const ProjectRoutes = () => {
  return (
    <React.Suspense fallback={<>Loading...</>} >
      <Routes>
        <Route path="/" element={<Signup />} />
        <Route path="*" element={<NotFound />} />
        <Route element={<ProtectedRoute />}>
        <Route path="/homemain" element={<HomeMain />}/> 
        <Route
          path="/myaccountmydetailsnine"
          element={<MyAccountMyDetailsNine />}
        />
        <Route path="/signupeleven" element={<SignupEleven />} />
        <Route path="/otpfour" element={<OTPFour />} />
        <Route path="/myaccountpassword" element={<MyAccountPassword />} />
        <Route path="/selectplansone" element={<SelectPlansOne />} />
        <Route path="/selectplans" element={<SelectPlans />} />
        <Route
          path="/paymentdetailseleven"
          element={<PaymentDetailsEleven />}
        />
        <Route
          path="/myaccountbillingone"
          element={<MyAccountbillingOne />}
        />
        <Route
          path="/myaccountbillingtwo"
          element={<MyAccountbillingTwo />}
        />
        <Route path="/editcarddetails" element={<EditCardDetails />} />
        <Route
          path="/allfeaturesandbenefitsone"
          element={<AllfeaturesandbenefitsOne />}
        />
        <Route
          path="/allfeaturesandbenefits"
          element={<Allfeaturesandbenefits />}
        />
        <Route path="/billingaddressten" element={<BillingAddressTen />} />
        <Route
          path="/addcarddetailsnineteen"
          element={<AddCardDetailsNineteen />}
        />
        <Route path="/settings" element={<Settings />} />
        <Route path="/loginfour" element={<LoginFour />} />
        <Route path="/logineight" element={<LoginEight />} />
        <Route path="/loginsix" element={<LoginSix />} />
        <Route path="/otpone" element={<OTPOne />} />
        <Route path="/modellibrary" element={<ModelLibrary />} />
        <Route
          path="/modellibrarysearchactive"
          element={<ModelLibrarysearchactive />}
        />
        <Route
          path="/modellibrarysearchresult"
          element={<ModelLibrarysearchresult />}
        />
        <Route
          path="/modellibrarynotfoundone"
          element={<ModelLibrarynotfoundOne />}
        />
        <Route path="/yourmodels" element={<YourModels />} />
        <Route
          path="/modellibrarynotfound"
          element={<ModelLibrarynotfound />}
        />
        <Route path="/modellibraryone" element={<ModelLibraryOne />} />
        <Route path="/our-home" element={<Home />} />
        <Route path="/frontend-select-techstack" element={<FETechStack />} />
        <Route path="/frontend-select-template" element={<FETemplate />} />
        <Route path="/fetemplateopen" element={<FETemplateopen />} />
        <Route path="/fetemplateadded" element={<FETemplateadded />} />
        <Route path="/fetemplatehover" element={<FETemplatehover />} />
        <Route
          path="/capabilitiesselected"
          element={<Capabilitiesselected />}
        />
        <Route path="/stepsthirteen" element={<StepsThirteen />} />
        <Route path="/stepstwentysix" element={<StepsTwentySix />} />
        <Route path="/stepstwentyseven" element={<StepsTwentySeven />} />
        <Route path="/stepstwentyeight" element={<StepsTwentyEight />} />
        <Route path="/stepstwentynine" element={<StepsTwentyNine />} />
        <Route path="/stepsthirty" element={<StepsThirty />} />
        <Route path="/stepsthirtyone" element={<StepsThirtyOne />} />
        <Route path="/frontend-codegen-detail" element={<FECodeGenDetail />} />
        <Route path="/codeone" element={<CodeOne />} />
        <Route path="/forgotpasswordone" element={<ForgotPasswordOne />} />
        <Route path="/forgotpasswordtwo" element={<ForgotPasswordTwo />} />
        <Route
          path="/forgotpasswordthree"
          element={<ForgotPasswordThree />}
        />
        <Route path="/forgotpasswordfour" element={<ForgotPasswordFour />} />
        <Route path="/forgotpasswordfive" element={<ForgotPasswordFive />} />
        <Route path="/backend-select-techstack" element={<BackEnd />} />
        <Route
          path="/capabilitiesselectedone"
          element={<CapabilitiesselectedOne />}
        />
        <Route path="/stepsfortythree" element={<StepsFortyThree />} />
        <Route path="/stepsfortyfour" element={<StepsFortyFour />} />
        <Route path="/stepsfortyfive" element={<StepsFortyFive />} />
        <Route path="/stepsfortysix" element={<StepsFortySix />} />
        <Route path="/stepsfortyseven" element={<StepsFortySeven />} />
        <Route path="/stepsfortyeight" element={<StepsFortyEight />} />
        <Route path="/stepsfortynine" element={<StepsFortyNine />} />
        <Route path="/frame53226" element={<Frame53226 />} />
        <Route path="/codegeneratedtwo" element={<CodeGeneratedTwo />} />
        <Route path="/codetwo" element={<CodeTwo />} />
        <Route
          path="/databaselockedthree"
          element={<DatabaselockedThree />}
        />
        <Route path="/databaselockedtwo" element={<DatabaselockedTwo />} />
        <Route path="/resetpasswordone" element={<ResetPasswordOne />} />
        <Route path="/resetpasswordtwo" element={<ResetPasswordTwo />} />
        <Route path="/resetpasswordthree" element={<ResetPasswordThree />} />
        <Route path="/resetpasswordfour" element={<ResetPasswordFour />} />
        <Route path="/resetpasswordfive" element={<ResetPasswordFive />} />
        <Route path="/resetpasswordnine" element={<ResetPasswordNine />} />
        <Route path="/resetpasswordten" element={<ResetPasswordTen />} />
        <Route path="/resetpasswordeight" element={<ResetPasswordEight />} />
        <Route path="/both-select-techstack" element={<FETechStackOne />} />
        <Route
          path="/fetechstackselectedone"
          element={<FETechStackselectedOne />}
        />
        <Route path="/both-select-template" element={<FETemplateOne />} />
        <Route path="/fetemplateopenone" element={<FETemplateopenOne />} />
        <Route path="/fetemplateaddedone" element={<FETemplateaddedOne />} />
        <Route path="/fetemplatehoverone" element={<FETemplatehoverOne />} />
        <Route path="/both-select-backend" element={<BackEndOne />} />
        <Route path="/databasetwo" element={<DatabaseTwo />} />
        <Route path="/both-select-db" element={<DatabaseThree />} />
        <Route
          path="/capabilitiesselectedtwo"
          element={<CapabilitiesselectedTwo />}
        />
        
        <Route path="/backend-basic-details" element={<BasicDetailsSeven />} />
        <Route path="/frontend-basic-details" element={<BasicDetailsFour />} />
        <Route path="/both-review" element={<Review />} />
        <Route path="/generating-frontend-code" element={<StepsOne />} />
        <Route path="/stepstwo" element={<StepsTwo />} />
        <Route path="/stepsthree" element={<StepsThree />} />
        <Route path="/stepsfour" element={<StepsFour />} />
        <Route path="/stepsfive" element={<StepsFive />} />
        <Route path="/stepsseven" element={<StepsSeven />} />
        <Route path="/stepseight" element={<StepsEight />} />
        <Route path="/stepsnine" element={<StepsNine />} />
        <Route path="/stepsten" element={<StepsTen />} />
        <Route path="/stepseleven" element={<StepsEleven />} />
        <Route path="/stepstwelve" element={<StepsTwelve />} />
        <Route path="/backend-codegen-detail" element={<BECodeGenDetail />} />
        <Route path="/code" element={<Code />} />
        <Route path="/yourmodelstwo" element={<YourModelsTwo />} />
        <Route path="/stepssix" element={<StepsSix />} />
        <Route path="/databaselocked" element={<Databaselocked />} />
        <Route path="/whats-new" element={<WhatsNew />} />
        <Route path="/help-and-support" element={<HelpandSupport />} />
        <Route path="/faqs" element={<FAQs />} />
        <Route path="/error" element={<Error />} />
        <Route path="/error1" element={<Error1 />} />
        <Route path="/dhiwise-dashboard" element={<Links />} />
        <Route
          path="/verticallinearstepper"
          element={<VerticalLinearStepper />}
        />
        <Route path="pricing" element={<Pricing />} /> 
        </Route>
        <Route path="frontend-app" element={<FrontendApp />} />
        <Route path="backend-app" element={<BackendApp />}/>
        <Route path="pre-built-apps" element={<PreBuiltApps />}/>
      </Routes>
    </React.Suspense>
  );
};
export default ProjectRoutes;
