import { Img, Input, Text } from 'components'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { Turn as Hamburger } from 'hamburger-react'

import appConfig from 'config/app.config.json';
import { useSelector } from 'react-redux';

const Header = (props) => {
  const { OnDrawerClicked, open } = props;
  const [inputvalue,setInputvalue] = useState();
  const [screenTitle,setScreenTitle] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const projectDetail = useSelector(store => store.basicInformation?.projectBasicDetail);

  useEffect(() => {
    const currentScreen = appConfig?.MenuItems.find(x => x.Path === location.pathname);
    setScreenTitle(currentScreen?.Name)
  },[location])

  return (
    <div className='flex justify-between items-center bg-gray_901 h-header'>
      <div className='flex gap-[24px] items-center'>
        <div className='flex items-center sm:w-[auto] justify-between gap-2'>
          <Hamburger toggled={open} toggle={OnDrawerClicked} color="#fff" size={20}/>
            <Img 
                src='images/CW-logo.png'
                className="w-[64px] h-[30px]"
            />
        </div>
        <Text
            className="font-medium text-gray_50 text-left w-[auto]"
            as="h5"
            variant="h5"
        >
        {screenTitle || projectDetail?.ProjectName}
        </Text>
      </div>
      <div className="flex items-start justify-end sm:hidden px-[12px]">
        <div className='flex gap-4 items-center'>
          <Input
            onChange={(e) => setInputvalue(e?.target?.value)}
            wrapClassName="bg-gray_902 border-[1px] border-gray_801 border-solid flex px-[12px] py-1 rounded-[4px] w-[53%] md:hidden mr-3"
            className="font-medium p-[0] placeholder:text-gray_804 text-[12px] text-gray_300 text-left w-[100%]"
            name="searchbox"
            placeholder="Search..."
            value={inputvalue}
            suffix={
              inputvalue?.length > 0 ? (
                <Img
                  src='images/close.png'
                  className="cursor-pointer ml-[35px] my-[auto] w-[16px]"
                  onClick={() => setInputvalue("")}
                />
              ) : (
                <Img
                  src="images/ic_round-search.png"
                  className="cursor-pointer ml-[35px] my-[auto] w-[16px] h-[16px]"
                  alt="search"
                />
              )} />
          <Img 
            src="images/img_question.svg"
            className="h-[20px] w-[20px] cursor-pointer"
            alt="home"
            onClick={() => navigate("/help-and-support")}
          />
          <Text
            className="font-medium text-gray_300 text-left w-[auto] shrink-0 sm:hidden cursor-pointer"
            variant="body3"
            onClick={() => navigate("/help-and-support")}
          >
            Help and Support
          </Text>
        </div>
      </div>
    </div>
  )
}

export default Header